import { getStore } from "@/store"

export default [
    {
        path: "talk/chat",
        component: () => import("@/layouts/FullContentLayout"),
        beforeEnter: onlyAuthorizedRouteEnter,
        children: [
            {
                path: "",
                name: "talk.chat",
                component: () => import("@/pages/talk/chat/ChatIndex"),
                children: [
                    {
                        path: ":attendanceId(\\d+)",
                        name: "talk.chat.show",
                        props: route => ({ attendanceId: parseInt(route.params.attendanceId) }),
                        component: () => import("@/pages/talk/chat/view/ViewAttendance"),
                    },
                    {
                        path: "new",
                        name: "talk.chat.new",
                        component: () => import("@/pages/talk/chat/new/ChatNew"),
                        props: route => ({
                            pipelineId: parseInt(route.query.pipelineId),
                            stageId: parseInt(route.query.stageId),
                            recipientId: parseInt(route.query.recipientId),
                            recipientName: route.query.recipientName,
                            recipientEntity: parseInt(route.query.recipientEntity),
                            recipientTo: Array.isArray(route.query.recipientTo) ? route.query.recipientTo : [route.query.recipientTo],
                            channelId: parseInt(route.query.channelId),
                        }),
                        meta: { transition: true },
                        beforeEnter(to, from, next) {
                            const currentUser = getStore().getters["auth/currentUser"]
                            const hasG1Permission = getStore().getters["auth/hasG1Permission"]

                            if (!currentUser.talk_start_attendance && !hasG1Permission) {
                                return next({ name: "notfound", query: { errorId: 403 } })
                            }

                            next()
                        },
                    },
                ],
            },
        ],
    },
    {
        path: "talk/chat/:attendanceId(\\d+)/embed",
        name: "talk.chat.show.embed",
        props: route => ({ attendanceId: parseInt(route.params.attendanceId) }),
        component: () => import("@/pages/talk/chat/ChatEmbedIndex"),
        beforeEnter: onlyAuthorizedRouteEnter,
    },
]

function onlyAuthorizedRouteEnter(to, from, next) {
    const currentUser = getStore().getters["auth/currentUser"]
    if (!currentUser.account.allows_talk_attendance || !currentUser.talk_user_as_attendant) {
        return next({ name: "notfound", query: { errorId: 403 } })
    }

    next()
}
