<template>
    <div class="pipe-login">
        <spinner-loader
            v-if="hasTokens && isLoading"
            opaque
            :visible="true"
        />
        <login-sidebar v-else>
            <!-- SSO Login -->
            <sso-login-form v-if="show === '3rdPartyLogin'" />
            <!-- Recover password -->
            <recover-password-form v-else-if="show === 'recoverPassword'" />
            <!-- Change password -->
            <change-password-form
                v-else-if="show === 'changePassword'"
                :user-hash
            />
            <change-password-form
                v-else-if="show === 'newPassword'"
                :user-hash
                is-new-password
            />
            <pending-approve v-else-if="show === 'PendingApproval'" />
            <!-- Login -->
            <login-form
                v-else
                :processing-login
                @submit="doLogin($event)"
                @social-login="redirectToSocial($event)"
            >
                <template #message>
                    <pipe-alert
                        v-show="error"
                        title="Não foi possível fazer o login."
                    >
                        {{ error }}
                    </pipe-alert>
                </template>
            </login-form>
        </login-sidebar>
        <div
            v-if="!isMobile"
            class="pipe-login__canvas"
        >
            <blog-post-slider />
        </div>
    </div>
</template>

<script>
import LoginForm from "@/pages/login/components/LoginForm"
import PipeAlert from "@/components/v2/PipeAlert"
import { getTokens, isDoubleSession } from "@/plugins/auth"
import LoginSidebar from "@/pages/login/components/LoginSidebar"
import SsoLoginForm from "@/pages/login/components/SSOLoginForm"
import SpinnerLoader from "@/components/SpinnerLoader"
import { mapActions, mapGetters } from "vuex"
import RedirectHandler from "@/mixins/RedirectHandler"
import PendingApprove from "@/pages/login/components/PendingApprove"
import environment from "@/shared/environment"
import { defineAsyncComponent } from "vue"
import useModalNotification from "@/composables/modalNotification"

export default {
    components: {
        PendingApprove,
        LoginForm,
        PipeAlert,
        LoginSidebar,
        SsoLoginForm,
        SpinnerLoader,
        ChangePasswordForm: defineAsyncComponent(() => import("./components/ChangePasswordForm")),
        RecoverPasswordForm: defineAsyncComponent(() => import("./components/RecoverPasswordForm")),
        BlogPostSlider: defineAsyncComponent(() => import("./components/BlogPostSlider")),
    },
    mixins: [
        RedirectHandler,
    ],
    props: {
        show: {
            type: String,
            default: null,
        },
    },
    setup() {
        return {
            modalNotification: useModalNotification(),
        }
    },
    data() {
        return {
            error: null,
            isLoading: true,
            isMobile: false,
            processingLogin: 0,
            loginSuccess: false,
        }
    },
    computed: {
        ...mapGetters({
            currentUser: "auth/currentUser",
        }),
        referer() {
            if (this.$route.params.referer) {
                return this.$route.params.referer
            } else if (this.$route.query.referer) {
                return this.$route.query.referer + window.location.hash
            }

            if (this.currentUser?.acl === "C1" && !!this.currentUser?.talk_user_as_attendant) {
                return "/v2/talk/chat"
            }

            return "/pipeline"
        },
        apiURL() {
            return environment.APP_API
        },
        socialCode() {
            return this.$route.query.code
        },
        currentLocation() {
            const { origin, pathname } = window.location
            return `${origin}${pathname}`
        },
        hasTokens() {
            return getTokens()
        },
        isAndroid() {
            return /Android/i.test(navigator.userAgent)
        },
        isIphone() {
            return /iPhone|iPad/i.test(navigator.userAgent)
        },
        userHash() {
            return this.$route.params.userHash ?? ""
        },
        doubleSession() {
            return isDoubleSession()
        },
    },
    created() {
        if (this.socialCode) {
            this.doSocialLogin(this.socialCode)
        } else if (this.hasTokens.access_token) {
            this.$http.get("/token/check", {
                headers: {
                    Authorization: this.hasTokens.access_token,
                },
            }).then(() =>
                this.redirectHandler(this.referer)
            ).catch(() =>
                this.isLoading = false
            )
        }

        /* Esconde blog posts no mobile */
        this.resizeHandler()
        if ((this.isIphone || this.isAndroid) && !localStorage.getItem("appPopup")) {
            this.modalNotification.warning(
                "Conheça o app Piperun",
                "Clique em <strong>Usar o app</strong> para ser redirecionado até a loja ou então em <strong>Continuar</strong> para fazer login pela versão web.",
                "Usar o app",
                "Continuar"
            ).then(({ value }) => {
                localStorage.setItem("appPopup", true)
                if (value) {
                    window.location = this.isAndroid ?
                        "https://play.google.com/store/apps/details?id=net.odig.piperun&hl=pt_BR" :
                        "https://apps.apple.com/br/app/piperun-crm/id1459253282"
                }
            })
        }
    },
    mounted() {
        if (this.$route.query.error) {
            this.error = this.$route.query.error
        }

        if (this.doubleSession) {
            this.error = "Você foi desconectado porque sua sessão foi encerrada ou outro computador fez um acesso com seu login."
        }

        window.addEventListener("resize", this.resizeHandler)
    },
    beforeUnmount() {
        window.removeEventListener("resize", this.resizeHandler)
    },
    methods: {
        ...mapActions({
            "authWithCredentials": "auth/authWithCredentials",
            "authWithSocial": "auth/authWithSocial",
        }),
        /* Faz o login */
        doLogin(formData) {
            this.error = null
            this.processingLogin = 1
            return this.authWithCredentials(formData)
                .then(() => {
                    /* Removes error and redirects */
                    this.error = null
                    this.processingLogin = 2
                    this.loginSuccess = true
                    return this.redirectHandler(this.referer)
                }).catch((error) => {
                    this.error = error
                    this.loginSuccess = false
                }).finally(() => {
                    if (!this.loginSuccess) {
                        this.processingLogin = 0
                    }
                })
        },
        redirectToSocial(service) {
            window.location = `${this.apiURL.split("v1")[0]}/sso/${service}?redirect_url=${this.currentLocation}`
        },
        doSocialLogin(code) {
            this.authWithSocial({
                code: code,
                redirect: this.currentLocation,
            }).then(() => {
                /* Removes error and redirects */
                this.error = null
                return this.redirectHandler(this.referer)
            }).catch(error => {
                this.isLoading = false
                this.error = error
            })
        },
        resizeHandler() {
            this.$nextTick(() => {
                this.isMobile = window.innerWidth <= 992
            })
        },
    },

}
</script>

<style lang="less">
@import "src/assets/styles/globals.less";

.pipe-login {
    height: 100%;
    display: flex;

    &__canvas {
        height: 100vh;
        flex: 1;
        flex-basis: 75%;
        overflow: hidden;
        background: @grey-1;
    }
}
</style>
